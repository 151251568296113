<template>
    <div class="company">
        <Header/>

        <div class="box">

            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>单位介绍</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="content">

                <div class="body">

                    <!--<div class="time">-->
                        <!--<div>发布时间：</div>-->
                        <!--<div>xxxx</div>-->
                        <!--<div style="margin-left: 5px">浏览次数：</div>-->
                        <!--<div>333</div>-->
                    <!--</div>-->
                    <div class="text" v-html="list"></div>
                </div>

            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import User from "../components/User.vue"
import {GetUnitByKey} from '../api/list'
    export default {
        components: {
            Header,
            Footer,
            User,
        },
        data() {
            return {
                list: ''
            }
        },
        created () {
                GetUnitByKey().then(res=>{
                    this.list=res.data

                })
            }
    }
</script>

<style  scoped>
@import url(../assets/css/Company.css);
</style>
